import {
  doc,
  onSnapshot,
  query,
  Unsubscribe,
  updateDoc,
  where,
} from "firebase/firestore";
import { WorkspaceDoc, WorkspacePermissionDoc } from "shared/workspaceTypes";
import { usersCollection } from "src/db/user";
import {
  workspacePermissionsCollectionGroup,
  workspacesCollection,
} from "src/db/workspace";
import { auth } from "src/firebase";
import { create } from "zustand";

interface UserWorkspacesStore {
  userWorkspacePermissions: Map<string, WorkspacePermissionDoc>;
  userWorkspaces: [WorkspaceDoc, WorkspacePermissionDoc][];
  activeWorkspaceId: string | null;
  subscribeToUserWorkspacePermissions: (userId: string) => Unsubscribe;
  subscribeToUserWorkspaces: (
    userWorkspacePermissions: Map<string, WorkspacePermissionDoc>
  ) => Unsubscribe;
  setActiveWorkspaceId: (workspaceId: string) => void;
  cleanup: () => void;
}

export const useUserWorkspacesStore = create<UserWorkspacesStore>((set) => ({
  userWorkspacePermissions: new Map(),
  userWorkspaces: [],
  activeWorkspaceId: null,
  setActiveWorkspaceId: async (workspaceId: string) => {
    const userId = auth.currentUser?.uid;
    if (!userId) {
      console.error("User is not logged in");
      return;
    }
    const userDoc = doc(usersCollection, userId);
    console.log("Updating userDoc with activeWorkspaceId", workspaceId);
    await updateDoc(userDoc, {
      activeWorkspaceId: workspaceId,
    });
    set({ activeWorkspaceId: workspaceId });
  },
  subscribeToUserWorkspacePermissions: (userId: string) => {
    const unsubscribe = onSnapshot(
      query(workspacePermissionsCollectionGroup, where("uid", "==", userId)),
      (snapshot) => {
        const workspacePermissionDocs = snapshot.docs.map((doc) => {
          const workspaceId = doc.ref.parent.parent?.id;
          if (!workspaceId) {
            return;
          }
          const permission = doc.data();
          return {
            workspaceId,
            ...permission,
          };
        });
        set({
          userWorkspacePermissions: new Map(
            workspacePermissionDocs
              .filter((p): p is NonNullable<typeof p> => p !== undefined)
              .map((p) => [p.workspaceId, p] as const)
          ),
        });
      }
    );
    return unsubscribe;
  },
  subscribeToUserWorkspaces: (
    userWorkspacePermissions: Map<string, WorkspacePermissionDoc>
  ) => {
    if (userWorkspacePermissions.size === 0) {
      set({ userWorkspaces: [] });
      return () => {};
    }
    console.log(
      "querying user workspaces with keys",
      Array.from(userWorkspacePermissions.keys())
    );
    const userWorkspacesQuery = query(
      workspacesCollection,
      where("__name__", "in", Array.from(userWorkspacePermissions.keys()))
    );
    const unsubscribe = onSnapshot(userWorkspacesQuery, (snapshot) => {
      const workspaceDocsWithPermission = snapshot.docs.map((doc) => {
        const workspaceId = doc.id;
        const permission = userWorkspacePermissions.get(workspaceId);
        if (!permission) {
          return;
        }
        return [doc.data() as WorkspaceDoc, permission] as const;
      });
      const filteredWorkspaces = workspaceDocsWithPermission.filter(
        (item): item is [WorkspaceDoc, WorkspacePermissionDoc] =>
          item !== undefined
      );
      // console.log(
      //   "Updated user workspace subscription, data:",
      //   JSON.stringify(filteredWorkspaces, null, 2)
      // );
      set({ userWorkspaces: filteredWorkspaces });
    });
    return unsubscribe;
  },
  cleanup: () => {
    set({
      userWorkspacePermissions: new Map(),
      userWorkspaces: [],
      activeWorkspaceId: null,
    });
  },
}));

export const useUserWorkspacePermissions = (): Map<
  string,
  WorkspacePermissionDoc
> => useUserWorkspacesStore((state) => state.userWorkspacePermissions);

export const useUserWorkspaces = (): [WorkspaceDoc, WorkspacePermissionDoc][] =>
  useUserWorkspacesStore((state) => state.userWorkspaces);

export const useUserWorkspacesIds = (): string[] => {
  const userWorkspaces = useUserWorkspaces();
  return userWorkspaces.map(([workspace]) => workspace.uid);
};

export const useActiveWorkspaceId = () =>
  useUserWorkspacesStore((state) => state.activeWorkspaceId);

export const useActiveWorkspaceData = () => {
  const activeWorkspaceId = useActiveWorkspaceId();
  const userWorkspaces = useUserWorkspaces();
  return (
    userWorkspaces?.find(
      ([workspace]) => workspace.uid === activeWorkspaceId
    )?.[0] || null
  );
};

export const useActiveWorkspacePermission = () => {
  const activeWorkspaceId = useActiveWorkspaceId();
  const userWorkspaces = useUserWorkspaces();
  return (
    userWorkspaces?.find(
      ([workspace]) => workspace.uid === activeWorkspaceId
    )?.[1] || null
  );
};

export const useActiveWorkspaceActions = () =>
  useUserWorkspacesStore((state) => ({
    setActiveWorkspaceId: state.setActiveWorkspaceId,
    cleanup: state.cleanup,
  }));

export const useActiveWorkspaceResources = () => {
  const activeWorkspaceData = useActiveWorkspaceData();

  return {
    aiCreditsExtra: activeWorkspaceData?.aiCreditsExtra || 0,
    aiCreditsPeriodic: activeWorkspaceData?.aiCreditsPeriodic || 0,
    aiCreditsTotal:
      (activeWorkspaceData?.aiCreditsPeriodic || 0) +
      (activeWorkspaceData?.aiCreditsExtra || 0),
    aiStorageTotalMb: activeWorkspaceData?.aiStorageTotalMb || 0,
    aiStorageUsedMb: activeWorkspaceData?.aiStorageUsedMb || 0,
    aiStorageRemainingMb:
      (activeWorkspaceData?.aiStorageTotalMb || 0) -
      (activeWorkspaceData?.aiStorageUsedMb || 0),
  };
};

export const useActiveWorkspaceSubscription = () => {
  const activeWorkspaceData = useActiveWorkspaceData();
  return {
    subscription: activeWorkspaceData?.subscription,
    activePlan: activeWorkspaceData?.activePlan || "free",
    sponsorId: activeWorkspaceData?.sponsorId,
    refreshCreditsTsMs: activeWorkspaceData?.refreshCreditsTsMs,
    subscriptionId: activeWorkspaceData?.subscription?.items[0].subscription,
  };
};
