import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  Flex,
  FormControl,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { IoCheckmarkOutline, IoLinkOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { useUserPermission } from "../../../Context/UserPermissionContext";
import { deleteDoc, doc, setDoc, updateDoc } from "../../../firebase";
import useNotification from "../../../hooks/useNotification";
import useProjectPermissions from "../../../hooks/useProjectPermissions";
import SharedWithAvatar from "../../Elements/SharedWithAvatar";

import { useQueryClient } from "@tanstack/react-query";
import { User } from "firebase/auth";
import { ProjectDoc, ProjectPermissionDoc } from "shared/projectTypes";
import {
  PERMISSIONS,
  PERMISSIONS_MAP,
  PERMISSION_TYPES,
} from "src/data/PermissionData";
import {
  getProjectPermissionsCollection,
  projectsCollection,
} from "src/db/project";
import { queryKeyUserProjects } from "src/db/queryKeys";
import { getUserId } from "src/services/UserSrv";
import { useAuth } from "../../../Auth/AuthProvider";
import { formatClipboardCopy } from "../../Utils/urlUtils";

export const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

const createdUserSharedWithDoc = async ({
  uid,
  email,
  projectId,
  permission,
}) => {
  const userSharedWithDocRef = doc(
    getProjectPermissionsCollection(projectId),
    uid
  );
  await setDoc(userSharedWithDocRef, {
    email,
    permission,
    uid,
  });
};

const updateUserPermission = async ({ userId, projectId, permission }) => {
  const userSharedWithDocRef = doc(
    getProjectPermissionsCollection(projectId),
    userId
  );
  if (permission === "none") {
    await deleteDoc(userSharedWithDocRef);
  } else {
    await setDoc(
      userSharedWithDocRef,
      {
        permission: permission,
      },
      { merge: true }
    );
  }
};

export const SharingSettingModal = ({
  isOpen,
  onClose,
  isCentered,
  size,
}: {
  isOpen: boolean;
  onClose: () => void;
  isCentered: boolean;
  size: string;
}) => {
  const { notify } = useNotification();
  const { currentUser }: { currentUser: User } = useAuth();

  const { projectId } = useParams();
  const { isEditor } = useUserPermission();
  const queryClient = useQueryClient();

  const userPermissions = useProjectPermissions(projectId);

  const setAuthorDataInProjectDoc = async () => {
    if (!currentUser || !currentUser.email) {
      return;
    }
    await updateDoc(doc(projectsCollection, projectId), {
      authorDisplayName: currentUser.displayName || currentUser.email,
      authorPhotoURL: currentUser.photoURL || "",
    });

    queryClient.invalidateQueries({ queryKey: [queryKeyUserProjects] });
  };

  /**
   * Check if the user is already shared with.
   *
   * @param {*} email
   */
  const checkCannotAdd = (email) => {
    if (!email) {
      notify({
        title: "Error",
        description: "Please enter an email",
        status: "error",
        position: "top-right",
      });
      return true;
    } else if (!isValidEmail(email)) {
      notify({
        title: "Error",
        description: "Please enter a valid email",
        status: "error",
        position: "top-right",
      });
      return true;
    } else if (currentUser.email === email) {
      notify({
        title: "Error",
        description: "You cannot invite yourself",
        status: "error",
        position: "top-right",
      });
      return true;
    } else if (userPermissions.find((user) => user.email === email)) {
      notify({
        title: "Error",
        description: "User already has access",
        status: "error",
        position: "top-right",
      });
      return true;
    } else {
      return false;
    }
  };

  /**
   * When user is invited, there should be an invocation of a function that retrieves the uid of the user with that email.
   */
  const InviteSection = () => {
    const [inviteEmail, setInviteEmail] = React.useState("");
    const [submitting, setSubmitting] = React.useState(false);
    const [permission, setPermission] = React.useState(PERMISSION_TYPES.READ);

    const { projectId } = useParams();

    const handleGrantAccess = async () => {
      if (checkCannotAdd(inviteEmail)) {
        setSubmitting(false);
        return;
      }
      // set displayName and photoURL in the project
      await setAuthorDataInProjectDoc();
      const uid = await getUserId(inviteEmail);
      // console.log("uid :>> ", uid);
      await createdUserSharedWithDoc({
        uid,
        email: inviteEmail,
        projectId,
        permission,
      });
      setInviteEmail("");
      navigator.clipboard.writeText(
        formatClipboardCopy({
          url: window.location.href,
          source: "sharing",
        })
      );
      // Display a toast to inform user to share the link with the user
      notify({
        title: "Success",
        description:
          "User has been granted access. Link to the project copied to clipboard. Share the link with the user.",
        status: "success",
        position: "top-right",
      });
      setSubmitting(false);
    };

    return (
      <Flex px="6" flexDir="column" position="relative">
        <FormControl
          bg="#282C3E"
          display="flex"
          flexDir="row"
          borderRadius="md"
          p="2"
          justifyContent="space-between"
        >
          <Text
            zIndex={12}
            position="absolute"
            bottom="0"
            color="red"
            fontSize="10px"
            fontWeight="regular"
            display={inviteEmail.length > 0 ? "block" : "none"}
          >
            {isValidEmail(inviteEmail) ? "" : "Invalid Email"}
          </Text>
          <Input
            color="white"
            _focusVisible={{ outline: "none" }}
            w="55%"
            border="none"
            placeholder="Email"
            p="0"
            pl="4"
            onChange={(e) => {
              setInviteEmail(e.target.value);
            }}
            value={inviteEmail}
          />
          <Menu gutter={-10}>
            <MenuButton
              p="0"
              fontWeight="500"
              fontSize="12px"
              as={Button}
              rightIcon={<ChevronDownIcon fontSize="15px" />}
            >
              {PERMISSIONS_MAP[permission]}
            </MenuButton>
            <MenuList
              css={{
                minWidth: "fit-content !important",
              }}
              fontWeight="500"
              bg="#282C3E"
              border="2px solid #191D30"
              p="2"
              borderRadius="md"
            >
              {PERMISSIONS.map((perm) => (
                <MenuItem
                  bg="#282C3E"
                  onClick={() => {
                    setPermission(perm);
                  }}
                  color="white"
                  fontSize="12px"
                  _hover={{ bg: "#191D30" }}
                  borderRadius="md"
                  key={perm}
                >
                  {PERMISSIONS_MAP[perm]}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
          <Button
            isLoading={submitting}
            p="0.7rem"
            fontWeight="500"
            fontSize="12px"
            w="17%"
            bg="#191D30"
            onClick={async () => {
              setSubmitting(true);
              await handleGrantAccess();
            }}
            isDisabled={isValidEmail(inviteEmail) ? false : true}
          >
            Invite
            {/* Grant access */}
          </Button>
        </FormControl>
      </Flex>
    );
  };

  const PermissionMenu = ({ currentPermission, setPermission }) => {
    return (
      <Menu placement="bottom" gutter={0}>
        <MenuButton
          p="0"
          fontWeight="500"
          fontSize="12px"
          as={Button}
          rightIcon={<ChevronDownIcon fontSize="15px" />}
          h="fit-content"
          isDisabled={!isEditor()}
        >
          {PERMISSIONS_MAP[currentPermission]}
        </MenuButton>
        <MenuList
          bg="#282C3E"
          border="none"
          p="2"
          borderRadius="md"
          as={Flex}
          w="12%"
          flexDir="column"
          css={{
            minWidth: "fit-content !important",
          }}
          gap={2}
        >
          {PERMISSIONS.map((perm) => (
            <MenuItem
              bg="#282C3E"
              onClick={() => setPermission(perm)}
              color="white"
              fontSize="12px"
              _hover={{ bg: "#191D30" }}
              borderRadius="md"
              key={perm}
            >
              {PERMISSIONS_MAP[perm]}
            </MenuItem>
          ))}
          <MenuItem
            bg="#282C3E"
            onClick={() => {
              setPermission("none");
            }}
            color="#D11A2A"
            fontSize="12px"
            _hover={{ bg: "#191D30" }}
            borderRadius="md"
          >
            Remove
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  const IndividualUser = ({
    userProjectPermission,
    projectId,
  }: {
    userProjectPermission: ProjectPermissionDoc;
    projectId: ProjectDoc["uid"];
  }) => {
    const { currentUser } = useAuth();
    const { isOwner } = useUserPermission();

    const isCurrentUser = userProjectPermission.uid === currentUser.uid;

    const permissionComponent =
      isCurrentUser || !isOwner() ? (
        // for yourself, and if you're not the owner,
        // do not show permissions menu
        <Text color="white" fontSize="12px">
          {PERMISSIONS_MAP[userProjectPermission.permission]}
        </Text>
      ) : (
        <PermissionMenu
          currentPermission={userProjectPermission.permission}
          setPermission={(permission) => {
            updateUserPermission({
              userId: userProjectPermission.uid,
              projectId,
              permission,
            });
          }}
        />
      );

    return (
      <Flex mt={2} justify="space-between" align="center" gap={1}>
        <Flex align="center" gap="2">
          <SharedWithAvatar
            displayName={
              userProjectPermission.displayName || userProjectPermission.email
            }
            photoURL={""}
          />
          <Text fontSize="11px" color="white">
            {userProjectPermission.displayName || userProjectPermission.email}{" "}
            {userProjectPermission.uid === currentUser.uid ? " (you)" : ""}
          </Text>
        </Flex>
        {permissionComponent}
      </Flex>
    );
  };

  const UserList = ({
    userList,
    projectId,
  }: {
    userList: ProjectPermissionDoc[];
    projectId: ProjectDoc["uid"];
  }) => {
    return (
      <Flex px="6" mt="2rem" flexDir="column">
        <Text color="#8C8E98" fontSize="11px">
          People with access
        </Text>
        <Flex flexDir="column">
          {userList
            .sort((a, b) => {
              if (a.uid === currentUser.uid) return -1;
              if (b.uid === currentUser.uid) return 1;
              return 0;
            })
            .map((user, index) => (
              <IndividualUser
                key={index}
                userProjectPermission={user}
                projectId={projectId}
              />
            ))}
        </Flex>
      </Flex>
    );
  };

  const CopyLinkButton = () => {
    const [clicked, setClicked] = useState(false);
    const [buttonText, setButtonText] = useState("Copy link");
    const [icon, setIcon] = useState(<IoLinkOutline fontSize="20px" />);

    const handleClick = () => {
      setClicked(true);
      setButtonText("Copied!");
      setIcon(<IoCheckmarkOutline fontSize="20px" />);
      // copy current URL to clipboard
      navigator.clipboard.writeText(
        formatClipboardCopy({
          url: window.location.href,
          source: "sharing",
        })
      );

      setTimeout(() => {
        setClicked(false);
        setButtonText("Copy link");
        setIcon(<IoLinkOutline fontSize="20px" />);
      }, 3000); // reset after 3 seconds
    };

    const buttonStyle = {
      transform: clicked ? "scale(0.95)" : "scale(1)",
      transition: "transform 0.3s ease",
    };

    return (
      <Button
        p="0"
        w="fit-content"
        fontSize="12px"
        color="#37BDDB"
        iconSpacing="2"
        my="4"
        leftIcon={icon}
        fontWeight="normal"
        style={buttonStyle}
        onClick={handleClick}
      >
        {buttonText}
      </Button>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={isCentered}
      size={size}
    >
      <ModalOverlay w="full" h="full" />
      <ModalContent
        bg="#191D30"
        pb="0"
        pt="4"
        position="fixed"
        top="30%"
        left="35%"
      >
        <ModalCloseButton
          boxSize="24px"
          bg="#282C3E"
          color="white"
          p="0"
          fontSize="9px"
        />
        <ModalHeader px="6" color="white">
          {isEditor() ? "Invite" : "Sharing"}
        </ModalHeader>
        {isEditor() ? <InviteSection /> : null}
        <UserList userList={userPermissions} projectId={projectId} />
        <Flex px="6" mt="10" flexDir="column">
          <Text color="#8C8E98" fontSize="11px">
            General access
          </Text>
          <Flex flexDir="row" justify="space-between" align="center">
            <Menu placement="bottom" gutter={0}>
              <MenuButton
                mt="2"
                px="2"
                fontWeight="normal"
                fontSize="12px"
                as={Button}
                rightIcon={<ChevronDownIcon fontSize="15px" />}
                border="1px solid #8C8E97"
                isDisabled={!isEditor()}
              >
                Only people invited to this project
              </MenuButton>
              <MenuList
                h="fit-content"
                bg="#282C3E"
                border="none"
                p="2"
                borderRadius="md"
              >
                <MenuItem
                  bg="#282C3E"
                  onClick={() => {
                    // setPermission("canView");
                  }}
                  color="white"
                  fontSize="12px"
                  _hover={{ bg: "#191D30" }}
                  borderRadius="md"
                >
                  Only people invited to this project
                </MenuItem>
              </MenuList>
            </Menu>
            <Text color="white" fontSize="12px" fontWeight="regular">
              Restricted
            </Text>
          </Flex>
        </Flex>
        <Divider mt="2" orientation="horizontal" borderColor="#282C3E" />
        <Flex px="6">
          <CopyLinkButton />
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default SharingSettingModal;
