import { ToastPosition, useToast } from "@chakra-ui/react";

const useNotification = () => {
  const toast = useToast();
  const notify = ({
    title,
    status,
    description,
    position = "top-right",
    duration = 3000,
    isClosable = true,
  }: {
    title: string;
    status: "success" | "error" | "warning" | "info";
    description?: string;
    position?: ToastPosition;
    duration?: number;
    isClosable?: boolean;
  }) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: duration,
      isClosable: isClosable,
      position: position,
    });
  };
  return { notify };
};

export default useNotification;
