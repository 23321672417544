import {
  Box,
  Divider,
  Flex,
  HStack,
  Link,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MdOutlineArrowBack, MdOutlinePublish } from "react-icons/md";
import { Link as ReactRouterLink, useHistory } from "react-router-dom";
import { useAuth } from "../../Auth/AuthProvider";

import { doc, getDoc } from "firebase/firestore";
import yaml from "js-yaml";
import { useParams } from "react-router-dom";
import Layout from "../Layout";
import AdminControls from "./AdminControls";
import { aiModulesCollection, moduleDataSubCollection } from "./AiModuleTypes";
import { InputCombinationWithTooltip } from "./InputCombinationWithTooltip";
import { MultistepModuleDefinition } from "./multistepAiModuleTypes";
import {
  createNewMultistepModule,
  releaseNewMultistepModuleVersion,
} from "./multistepAiModuleUtils";

const validateModuleDefinition = (data: MultistepModuleDefinition) => {
  const missingFields: string[] = [];
  if (!data.config.name) missingFields.push("name");
  if (!data.config.description) missingFields.push("description");
  if (!data.config.steps) missingFields.push("steps");
  return missingFields;
};

const handleCreateCustomAiModule = async (
  moduleDefinition: MultistepModuleDefinition,
  userId: string,
  userEmail: string,
  toast,
  history
) => {
  console.log("moduleDefinition: ", moduleDefinition);
  const missingFields = validateModuleDefinition(moduleDefinition);

  if (missingFields.length > 0) {
    // alert(`Please fill in the following fields: ${missingFields.join(", ")}`);
    toast({
      title: "Please fill in the following fields",
      description: missingFields.join(", "),
      status: "error",
      duration: 9000,
      isClosable: true,
      position: "top-right",
    });
    return;
  }
  await createNewMultistepModule(moduleDefinition, userId, userEmail);

  toast({
    title: "Module created",
    description: "Your module has been created successfully",
    status: "success",
    duration: 9000,
    isClosable: true,
    position: "top-right",
  });

  history.push("/user?tab=custom-ai");
};

const handleReleaseNewModuleVersion = async (
  moduleId: string,
  moduleDefinition: MultistepModuleDefinition,
  toast,
  history
) => {
  const missingFields = validateModuleDefinition(moduleDefinition);

  if (missingFields.length > 0) {
    // alert(`Please fill in the following fields: ${missingFields.join(", ")}`);
    toast({
      title: "Please fill in the following fields",
      description: missingFields.join(", "),
      status: "error",
      duration: 9000,
      isClosable: true,
      position: "top-right",
    });
    return;
  }
  await releaseNewMultistepModuleVersion(moduleId, moduleDefinition);

  toast({
    title: "Module version released",
    description: "Your module version has been released successfully",
    status: "success",
    duration: 9000,
    isClosable: true,
    position: "top-right",
  });

  history.push("/user?tab=custom-ai");
};

export const MultistepAiModuleEditor = () => {
  // retrieve query params
  const { moduleId, versionId } = useParams<{ moduleId: string }>();
  const { currentUser, isAiModulesAdmin } = useAuth();

  const [moduleConfig, setModuleConfig] = useState<
    MultistepModuleDefinition["config"]
  >({
    name: "",
    description: "",
    steps: [],
  });
  const [moduleConfigStr, setModuleConfigStr] = useState("");
  const [yamlError, setYamlError] = useState(false);

  const toast = useToast();
  const history = useHistory();

  /**
   * Fetch the module data from the database
   */
  useEffect(() => {
    if (!moduleId && !versionId) return;

    // retrieve module data from the database
    const redirectToActiveVersion = async () => {
      const moduleRef = doc(aiModulesCollection, moduleId);
      const moduleDocData = await getDoc(moduleRef);
      if (!moduleDocData.exists()) {
        // redirect to the home page
        history.push("/");
      }

      const moduleData = moduleDocData.data();
      history.push(
        `/multistep-ai-module-editor/${moduleId}/${moduleData?.activeVersionId}`
      );
    };

    if (moduleId && !versionId) {
      redirectToActiveVersion();
    }

    if (moduleId && versionId) {
      const loadModuleData = async () => {
        const moduleYamlRef = moduleDataSubCollection(
          moduleId,
          versionId,
          "definition"
        );
        const moduleYamlDoc = await getDoc(moduleYamlRef);
        const moduleYamlData =
          moduleYamlDoc.data() as MultistepModuleDefinition;

        setModuleConfig(moduleYamlData.config);

        setModuleConfigStr(yaml.dump(moduleYamlData.config));
      };

      loadModuleData();
    }
  }, [moduleId, versionId]);

  /*
   * Parse the YAML string into a an object
   */
  useEffect(() => {
    try {
      yaml.load(moduleConfigStr);
      setYamlError(false);
    } catch (e) {
      console.error("Error parsing YAML string: ", e);
      setYamlError(true);
      return;
    }
    setModuleConfig(
      yaml.load(moduleConfigStr) as MultistepModuleDefinition["config"]
    );
  }, [moduleConfigStr]);

  console.log("moduleId :>> ", moduleId);
  console.log("versionId :>> ", versionId);
  console.log("moduleConfigStr :>> ", moduleConfigStr);
  console.log("moduleConfig :>> ", moduleConfig);

  return (
    <Layout hideAssistant={true}>
      <Flex flexDir="column" w="60%">
        <Box my="6">
          <Flex gap={2} direction="row" align="center" justify="space-between">
            <HStack gap={0}>
              <Link as={ReactRouterLink} to="/user?tab=custom-ai">
                <MdOutlineArrowBack color="primary" fontSize="18px" />
              </Link>
              <Flex h="14px" align={"center"}>
                <Text
                  fontWeight="medium"
                  fontSize="14px"
                  border="none"
                  pl="8px"
                  w="fit-content"
                >
                  {moduleConfig?.name || "New Module"}
                </Text>
              </Flex>
            </HStack>
            <Flex
              color="#727272"
              fontSize="12px"
              align="center"
              cursor="pointer"
              onMouseDown={async () => {
                if (!(moduleId && versionId)) {
                  await handleCreateCustomAiModule(
                    {
                      createdBy: currentUser.uid,
                      config: moduleConfig,
                    },
                    currentUser.uid,
                    currentUser.email,
                    toast,
                    history
                  );
                } else {
                  await handleReleaseNewModuleVersion(
                    moduleId,
                    {
                      createdBy: currentUser.uid,
                      config: moduleConfig,
                    },
                    toast,
                    history
                  );
                }
              }}
            >
              <MdOutlinePublish fontSize="16px" />
              <Text>Publish</Text>
            </Flex>
          </Flex>
        </Box>
        <Flex gap={6} flexDir="column">
          <InputCombinationWithTooltip
            title="YAML Config"
            tooltipText=""
            placeholder="YAML"
            width="100%"
            height="50vh"
            inputType="textarea"
            onChange={(value) => setModuleConfigStr(value as string)}
            defaultValue={moduleConfigStr}
          />
          {yamlError && (
            <Text color="red.500" fontSize="12px">
              Error parsing YAML string
            </Text>
          )}
          <Divider borderColor="black" />
          {isAiModulesAdmin && <AdminControls />}
        </Flex>
      </Flex>
    </Layout>
  );
};
