import {
  Avatar,
  Box,
  Circle,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BiCheck, BiLinkExternal } from "react-icons/bi";
import {
  BsArrowsAngleContract,
  BsArrowsAngleExpand,
  BsBookmark,
  BsBookmarkFill,
  BsThreeDots,
} from "react-icons/bs";
import { FiEye, FiEyeOff } from "react-icons/fi";
import {
  MdEdit,
  MdModeComment,
  MdOutlineComment,
  MdOutlineContentCopy,
  MdOutlineFileDownloadDone,
  MdPostAdd,
} from "react-icons/md";
import ReactMarkdown from "react-markdown";
import { useUserPermission } from "../../../Context/UserPermissionContext";
import {
  SNIPPET_TYPE_DATA,
  VIEW_IN_SOURCE_ENABLED,
} from "../../../data/SnippetTypeData";
import useSnippetEdit from "../../../hooks/useSnippetEdit";
import { copyInsightCardTextToClipboard } from "../../Utils/insightUtils";
import { applyBold } from "../../Utils/markdownUtils";
import { ManualInsightSourceList } from "../../assistant/ManualInsightSource/ManualInsightSourceList";
import ImageViewer from "../ImageViewer";
import InsightCardContent from "../InsightCardContent";
import style from "../InsightCardContent.module.css";
import PermissionWrapper from "src/Components/HigherOrderComponents/PermissionWrapper";
import { PERMISSION_ERROR_MSG } from "src/data/PermissionData";

function CircledInsightIcon({ insight }) {
  return (
    <Circle size="20px" bg={SNIPPET_TYPE_DATA[insight.type]?.color}>
      <Icon
        as={SNIPPET_TYPE_DATA[insight.type]?.icon}
        fontSize="14px"
        color="black"
        zIndex={insight.sourceArticles?.length + 1}
        top="50%"
        left="50%"
      />
    </Circle>
  );
}

const DefaultAndCompactCard = ({
  insight,
  activeInsight,
  viewInSourceHandler,
  commentHandler,
  saveHandler,
  mode,
  relatedArticleHandler,
  isExpanded,
  isInsightPage,
  setIsExpanded,
  updateSnippetCurated,
  fullText,
  projectId,
  insightId,
}) => {
  const CommentIcon =
    insight?.comments?.length > 0 ? MdModeComment : MdOutlineComment;
  const { onClose, onOpen, isOpen } = useDisclosure();
  const { isReader } = useUserPermission();

  const {
    showEdit,
    setIsEditing,
    isEditing,
    setShowEdit,
    setUpdatedText,
    updatedText,
    updateSnippetText,
  } = useSnippetEdit(insight.text);

  return (
    <Flex px={4} py={2}>
      <HStack flex={1} gap={4} align="flex-start">
        <HStack>
          <Flex flexDir="column">
            <CircledInsightIcon insight={insight} />
            <Flex>
              {insight.type === "assistantInsight" && (
                <ManualInsightSourceList
                  iconsLists={insight.sourceArticles}
                  iconsGaps="-25%"
                  iconsToRender={mode === "DEFAULT" ? 2 : 1}
                  counterGap={mode === "DEFAULT" ? "-20%" : "-25%"}
                />
              )}
            </Flex>
          </Flex>

          {mode === "DEFAULT" && (
            <Text pl={3} opacity={0.54} fontSize="sm" minW="70px" pt={2}>
              {SNIPPET_TYPE_DATA[insight.type]?.name}
            </Text>
          )}
          {insight.type === "image" ? (
            <Box pt={1} onClick={onOpen} cursor="pointer">
              <Avatar
                borderRadius="10"
                src={insight.imageUrl || "https://via.placeholder.com/200"}
                size="sm"
              />
            </Box>
          ) : null}
        </HStack>

        {activeInsight === insight.uid ? (
          <InsightCardContent
            type={insight.type}
            activeHighlight={activeInsight}
            text={insight.text}
            bold={insight.bold}
            insightId={insight.uid}
            fullText={fullText}
          />
        ) : (
          <>
            {isEditing ? (
              <HStack
                spacing={2}
                my={2}
                justifyContent="flex-end"
                alignItems="center"
                w="full"
              >
                <Input
                  value={updatedText}
                  onChange={(e) => setUpdatedText(e.target.value)}
                  placeholder="medium size"
                  size="md"
                />
                <Icon
                  onClick={() => updateSnippetText(projectId, insightId)}
                  cursor="pointer"
                  mt={3}
                  boxSize={6}
                  as={BiCheck}
                />
                <Icon
                  cursor="pointer"
                  color="red"
                  mt={3}
                  boxSize={6}
                  as={AiOutlineClose}
                  onClick={() => {
                    setIsEditing(false);
                    setShowEdit(false);
                  }}
                />
              </HStack>
            ) : (
              <Flex
                direction="row"
                w="full"
                onMouseEnter={() => setShowEdit(true)}
                onMouseLeave={() => setShowEdit(false)}
              >
                <Text
                  fontSize="xs"
                  lineHeight="1.6em"
                  noOfLines={isExpanded || mode === "DEFAULT" ? undefined : 1}
                  maxW="80%"
                  pt={3}
                  mr={4}
                >
                  <ReactMarkdown className={style.reactMarkDown}>
                    {applyBold(insight.text, insight.bold)}
                  </ReactMarkdown>
                </Text>
                {insight.type === "image" && showEdit && (
                  <Icon
                    cursor="pointer"
                    mt={3}
                    as={MdEdit}
                    onClick={() => setIsEditing(true)}
                  />
                )}
              </Flex>
            )}
          </>
        )}
        <Spacer />
        <Flex align="center" cursor="pointer">
          <IconButton
            conButton
            variant="ghost"
            colorScheme="gray"
            aria-label="Copy"
            icon={<MdOutlineContentCopy />}
            onClick={() => {
              copyInsightCardTextToClipboard(insight.text);
            }}
            fontSize="18px"
          />
          {isInsightPage && (
            <>
              {insight.type !== "inTextLink" && (
                <>
                  {!insight.curated ? (
                    <IconButton
                      variant="ghost"
                      colorScheme="gray"
                      aria-label="Save"
                      icon={<BsBookmark />}
                      onClick={() => updateSnippetCurated(insight.uid, true)}
                      isDisabled={isReader()}
                    />
                  ) : (
                    <IconButton
                      variant="ghost"
                      colorScheme="gray"
                      aria-label="Unsave"
                      icon={<BsBookmarkFill />}
                      onClick={() => updateSnippetCurated(insight.uid, false)}
                      isDisabled={isReader()}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Flex>

        {mode === "COMPACT" && (
          <>
            {activeInsight !== insight.uid && (
              <IconButton
                variant="ghost"
                colorScheme="gray"
                aria-label="See menu"
                onClick={() => setIsExpanded(!isExpanded)}
                icon={
                  isExpanded ? (
                    <BsArrowsAngleContract />
                  ) : (
                    <BsArrowsAngleExpand />
                  )
                }
              />
            )}
          </>
        )}
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<BsThreeDots />}
            colorScheme="gray"
            variant="ghost"
          ></MenuButton>
          <MenuList minW={0} w="auto" pr={4} bg="primary" color="white">
            <MenuItem
              bg="primary"
              onClick={commentHandler}
              icon={<CommentIcon />}
            >
              Comments
            </MenuItem>
            {(mode === "DEFAULT" || !isInsightPage) &&
              insight.type !== "manualInsight" &&
              insight.type !== "image" && (
                <>
                  {insight.type !== "inTextLink" && (
                    <PermissionWrapper>
                      {(hasEditPermission) =>
                        !insight.curated ? (
                          <MenuItem
                            onClick={() => saveHandler(insight.uid, true)}
                            bg="primary"
                            icon={<BsBookmark />}
                            isDisabled={!hasEditPermission}
                            title={
                              !hasEditPermission ? PERMISSION_ERROR_MSG : ""
                            }
                          >
                            Save
                          </MenuItem>
                        ) : (
                          <MenuItem
                            onClick={() => saveHandler(insight.uid, false)}
                            bg="primary"
                            icon={<BsBookmarkFill />}
                            isDisabled={!hasEditPermission}
                            title={
                              !hasEditPermission ? PERMISSION_ERROR_MSG : ""
                            } 
                          >
                            Unsave
                          </MenuItem>
                        )
                      }
                    </PermissionWrapper>
                  )}
                </>
              )}
            {VIEW_IN_SOURCE_ENABLED.includes(insight?.type) && (
              <>
                {activeInsight !== insight.uid ? (
                  <MenuItem
                    bg="primary"
                    onClick={() => viewInSourceHandler(insight.uid)}
                    icon={<FiEye />}
                  >
                    View in source
                  </MenuItem>
                ) : (
                  <MenuItem
                    onClick={() => viewInSourceHandler(null)}
                    bg="primary"
                    icon={<FiEyeOff />}
                  >
                    Close
                  </MenuItem>
                )}
              </>
            )}
            {insight?.type === "inTextLink" && (
              <>
                {insight.inTextLinkAddedToProject ? (
                  <MenuItem bg="primary" icon={<MdOutlineFileDownloadDone />}>
                    Article Added
                  </MenuItem>
                ) : (
                  <MenuItem
                    onClick={() =>
                      relatedArticleHandler(
                        insight.uid,
                        insight.url || insight.text
                      )
                    }
                    bg="primary"
                    icon={<MdPostAdd />}
                    isDisabled={isReader()}
                  >
                    Add Article
                  </MenuItem>
                )}
                <Link href={insight.url || insight.text} isExternal>
                  <MenuItem bg="primary" icon={<BiLinkExternal />}>
                    View Article
                  </MenuItem>
                </Link>
              </>
            )}
          </MenuList>
        </Menu>
      </HStack>
      <ImageViewer
        imageUrl={insight.imageUrl}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Flex>
  );
};

export default DefaultAndCompactCard;
