import { ChakraProvider } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import posthog from "posthog-js";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import chakraTheme from "../src/theme";
import App from "./App";
import { ZoomContextProvider } from "./Context/ZoomContext";
import "./index.css";
import { store } from "./store/store";

if (process.env.REACT_APP_USE_HOTJAR === "enable") {
  const script = document.createElement("script");
  script.type = "text/javascript";

  script.innerHTML = `
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 3258186, hjsv: 6 };
      a = o.getElementsByTagName("head")[0];
      r = o.createElement("script");
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
  `;

  document.getElementById("hotjar-script")?.appendChild(script);
  console.log("Hotjar is enabled");
} else {
  console.log("Hotjar is disabled");
}

if (process.env.REACT_APP_USE_SENTRY === "enable") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [process.env.REACT_APP_DOMAIN || ""],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

if (process.env.REACT_APP_USE_POSTHOG === "enable") {
  posthog.init("phc_IpevSN3MFxeEGKrc4oANCSM0ksCkuMKnVNOs3lcTBhL", {
    api_host: "https://eu.i.posthog.com",
    person_profiles: "always",
  });
  console.log("Posthog is enabled");
} else {
  console.log("Posthog is disabled");
}

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
  <Provider store={store}>
    <ChakraProvider resetCSS theme={chakraTheme}>
      <QueryClientProvider client={queryClient}>
        <ZoomContextProvider>
          <App />
        </ZoomContextProvider>
      </QueryClientProvider>
    </ChakraProvider>
  </Provider>

  // </React.StrictMode>
);
