import { Button, Flex, Text } from "@chakra-ui/react";
import React from "react";

export const WorkspaceAcceptInvitePage = () => {
  return (
    <Flex h="100vh" bg="white">
      <Flex w="43%" flexDir="column">
        <Flex
          px="120px"
          display="flex"
          flexDirection="column"
          justify="center"
          h="100%"
          w="100%"
          align="center"
        >
          <Flex flexDir="column" w="380px">
            <Text
              fontSize="16px"
              color="primary"
              opacity="0.56"
              mb={2}
              fontWeight="600"
              pb="24px"
            >
              Welcome to MosaiQ!
            </Text>

            <Text fontWeight="bold" mb={4} fontSize="36px">
              Join Lalla Johnson’s workspace on MosaiQ
            </Text>
            <Text
              fontSize="16px"
              fontWeight="400"
              color="primary"
              opacity="0.56"
              pb="46px"
            >
              Once you joined you will be able to view, edit, and share project
              on Lalla Johnson’s workspace.
            </Text>
            <Button
              bg="primary"
              color="white"
              w="100%"
              h="fit-content"
              mt="24px"
              py="11px  "
              fontSize="14px"
              fontWeight="400"
            >
              Join the workspace
            </Button>
          </Flex>
        </Flex>
        <Text
          w="100%"
          textAlign="center"
          pb="61px"
          fontSize="12px"
          color="primary"
          opacity="0.56"
        >
          © 2024 MosaiQ Lab
        </Text>
      </Flex>
      <Flex flex="1" w="57%" bg="primary" />
    </Flex>
  );
};
