import { MinusIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Grid,
  Spinner,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { FiMinusCircle } from "react-icons/fi";
import { IoMdAddCircleOutline } from "react-icons/io";
import { MdOutlineArrowUpward, MdOutlineEdit } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../Auth/AuthProvider";

export const AiModuleBox = ({
  moduleData,
  isLoading,
  action,
  accentColor = "",
  width,
  onClick = () => {
    () => {
      console.log("No action provided");
    };
  },
  actionAlwaysOn = false,
  actionOnlyOnIcon = false,
}) => {
  const [showActionIcon, setShowActionIcon] = useState(false);
  const { addCustomAiModule, removeCustomAiModule } = useAuth();
  const history = useHistory();

  accentColor = accentColor || moduleData.accentColor;

  const isMultistepModule = !!moduleData.api_version;

  const ACTION = {
    ADD: {
      toolTip: "Add Module",
      id: "add",
      icon: <IoMdAddCircleOutline fontSize="12px" color="black" />,
      action: () => {
        addCustomAiModule(moduleData.uid);
      },
    },
    EDIT: {
      toolTip: "Edit Module",
      id: "edit",
      icon: <MdOutlineEdit fontSize="10px" />,
      action: () => {
        if (isMultistepModule) {
          history.push(`/multistep-ai-module-editor/${moduleData.uid}`);
        } else {
          history.push(`/ai-module-editor/${moduleData.uid}`);
        }
      },
    },
    SEND: {
      toolTip: "Click to send",
      id: "send",
      icon: <MdOutlineArrowUpward fontSize="10px" />,
      action: onClick,
    },
    DELETE: {
      toolTip: "Remove Module",
      id: "delete",
      icon: <MinusIcon fontSize="10px" />,
      action: () => {
        removeCustomAiModule(moduleData.uid);
      },
    },
    ALREADY_ADDED_INDICATOR: {
      toolTip: "Module Already Added",
      id: "delete",
      icon: <FaRegCheckCircle fontSize="10px" color="#06b2e3" />,
      action: () => {
        null;
      },
    },
    PRIVATE_INDICATOR: {
      toolTip: "Private modules cannot be removed",
      id: "private",
      icon: <FiMinusCircle fontSize="10px" />,
      action: () => {
        () => {
          console.log("No action provided");
        };
      },
      cursor: "default",
    },
  };

  const theAction = ACTION[action];

  return (
    <Button
      // color="black"
      position="relative"
      _before={
        accentColor && {
          //Add a stripe
          content: '""',
          display: "block",
          position: "absolute",
          top: "0",
          left: "0",
          width: "4px",
          height: "100%",
          backgroundColor: accentColor,
          borderRadius: "4px 0 0 4px",
        }
      }
      bg="white"
      borderColor="#e0e0e0"
      variant="outline"
      flex="1"
      flexDirection="column"
      justifyContent="start"
      alignItems="start"
      height="auto"
      cursor={actionOnlyOnIcon ? "default" : theAction?.cursor || "pointer"}
      p="8px"
      pl={accentColor ? "12px" : "8px"}
      whiteSpace="normal"
      textAlign="left"
      spinner={
        <Spinner
          thickness="2px"
          speed="0.65s"
          emptyColor="black"
          color="black"
          size="sm"
        />
      }
      onClick={actionOnlyOnIcon ? null : theAction?.action}
      isLoading={isLoading}
      _hover={{
        bg: "#f3f3f3",
      }}
      onMouseEnter={() => setShowActionIcon(true)}
      onMouseLeave={() => setShowActionIcon(false)}
      pos="relative"
      w={width || "170px"}
      h="57px"
    >
      <Tooltip
        label={ACTION[action]?.toolTip}
        hasArrow
        bg="primary"
        p="2"
        px="4"
        borderRadius="8px"
        placement="top"
      >
        <Flex
          boxSize="16px"
          // bg="white"
          align="center"
          justify="center"
          borderRadius="100px"
          display={actionAlwaysOn ? "flex" : showActionIcon ? "flex" : "none"}
          position="absolute"
          top="3px"
          right="3px"
          cursor={theAction?.cursor || "pointer"}
          onClick={theAction?.action}
        >
          {theAction?.icon}
        </Flex>
      </Tooltip>

      <VStack align="start" spacing="0" w="100%">
        <Text
          fontSize="10px"
          fontWeight="semibold"
          color="black"
          opacity={0.87}
          mb="3px"
          noOfLines={1}
          // to avoid overlap with the icon
          w="93%"
        >
          {moduleData.title}
        </Text>
        <Text
          fontSize="10px"
          opacity={0.54}
          noOfLines={2}
          fontWeight={"medium"}
        >
          {moduleData.description}
        </Text>
      </VStack>
    </Button>
  );
};

export const CustomPromptListGrid = ({ children }) => {
  return (
    <Grid w="100%" gap="8px" templateColumns="repeat(auto-fit, 170px)">
      {children}
    </Grid>
  );
};
