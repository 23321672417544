import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import { removeUndefinedNull } from "../Components/Utils/dbUtils.js";
import { SectionCardData } from "../Components/Utils/template";
import {
  collection,
  db,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  q,
  setDoc,
  updateDoc,
  writeBatch,
} from "../firebase.js";
import { TestTypesInsideFunc } from "../../functions/shared/testTypesInsideFunc";

const testTypesInsideFunc: TestTypesInsideFunc = {
  name: "John",
  age: 30,
};

console.log(testTypesInsideFunc);

export const getTemplateList = (projectId, setTemplateListDataCallback) => {
  const templateListQuery = q(
    collection(db, `projects/${projectId}/templates`)
  );
  const unsubscribe = onSnapshot(templateListQuery, (querySnapshot) => {
    const templateListData: DocumentData[] = [];
    querySnapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
      templateListData.push(doc.data());
    });
    console.log(
      "Updated template data from db for project",
      projectId,
      templateListData
    );
    setTemplateListDataCallback(templateListData);
  });
  return unsubscribe;
};

export const subToTemplateSections = (
  projectId,
  templateId,
  setOrganizerSectionsDataCallback
) => {
  const organizerSectionsQuery = q(
    collection(
      db,
      `projects/${projectId}/templates/${templateId}/organizerSections`
    )
  );
  // templateListQuery = q(organizerQuery, orderBy("order", "asc"));
  const unsubscribe = onSnapshot(organizerSectionsQuery, (querySnapshot) => {
    const organizerSectionsData: DocumentData[] = [];
    querySnapshot.forEach((doc) => {
      organizerSectionsData.push({ id: doc.id, ...doc.data() });
    });
    console.log(
      "Updated organizer data from db for project",
      projectId,
      organizerSectionsData
    );
    setOrganizerSectionsDataCallback(organizerSectionsData);
  });
  return unsubscribe;
};

export const createChatGptRequest = (generationId, payload, callback) => {
  const chatGptDocRef = doc(
    db,
    `getChatGptResponseIncomingRequests/${generationId}`
  );
  setDoc(chatGptDocRef, payload)
    .then((result) => callback(result))
    .catch((e) => {
      console.log(e);
      callback(e);
    });
};

export const createTemplate = (templateId, payload, projectId) => {
  const templateDocRef = doc(
    db,
    `projects/${projectId}/templates/${templateId}`
  );
  setDoc(templateDocRef, payload).catch((e) => {
    console.log(e);
  });
};

export const deleteTemplate = (templateId, projectId) => {
  const templateDocRef = doc(
    db,
    `projects/${projectId}/templates/${templateId}`
  );
  deleteDoc(templateDocRef);
};

export const createSection = ({
  templateId,
  payload,
  projectId,
  sectionId,
}) => {
  const sectionDocRef = doc(
    db,
    `projects/${projectId}/templates/${templateId}/organizerSections/${sectionId}`
  );
  setDoc(sectionDocRef, payload).catch((e) => {
    console.log(e);
  });
};

export const deleteSection = ({ sectionId, projectId, templateId }) => {
  const organizerSectionDocRef = doc(
    db,
    `projects/${projectId}/templates/${templateId}/organizerSections/${sectionId}`
  );
  deleteDoc(organizerSectionDocRef);
};

export const getProjectGenerations = (projectId, callback) => {
  let generationsQuery = q(collection(db, `projects/${projectId}/generations`));
  generationsQuery = q(generationsQuery, orderBy("createdAt", "desc"));
  const unsubscribe = onSnapshot(generationsQuery, (querySnapshot) => {
    console.log("Updated generations data from db for project", projectId);
    const generationsData: DocumentData[] = [];
    querySnapshot.forEach((doc) => {
      generationsData.push({ ...doc.data(), uid: doc.id });
    });
    console.log(
      "Updated generations data from db for project",
      projectId,
      generationsData
    );
    callback(generationsData);
  });
  return unsubscribe;
};

export const updateTemplate = ({
  projectId,
  templateId,
  name,
  id,
  description,
  ...rest
}) => {
  const templateDocRef = doc(
    db,
    "projects/" + projectId + "/templates/" + templateId
  );
  const organizer = {
    name,
    description,
    id,
    ...rest,
  };
  removeUndefinedNull(organizer);
  updateDoc(templateDocRef, organizer);
};

export const updateTemplateSection = async ({
  projectId,
  name,
  order,
  snippets,
  templateId,
  id: sectionId,
}: {
  projectId: string;
  name?: string;
  order?: number;
  snippets?: SectionCardData[];
  templateId: string;
  id: string;
}) => {
  const organizerDocRef = doc(
    db,
    "projects/" +
      projectId +
      "/templates/" +
      templateId +
      "/organizerSections/" +
      sectionId
  );
  const organizer = {
    name,
    order,
    snippets,
    id: sectionId,
  };
  console.log("attemping to update organizer :>> ", organizer);
  removeUndefinedNull(organizer);
  await updateDoc(organizerDocRef, organizer);
};

export const updateDocuments = (documents, projectId, templateId) => {
  const batch = writeBatch(db);
  documents.map(async (document) => {
    const organizerDocRef = doc(
      db,
      `projects/${projectId}/templates/${templateId}/organizerSections/${document.id}`
    );
    batch.update(organizerDocRef, document, { merge: true });
  });
  const response = batch.commit();
  return response;
};
