import { Avatar, Button, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import {
  useActiveWorkspaceActions,
  useActiveWorkspaceId,
} from "src/stores/userWorkspaces";
import { useUserWorkspaces } from "../../stores/userWorkspaces";

const WorkSpaceMenuItem = ({
  imageUrl,
  workspaceName,
  workspaceId,
  showSettings,
}) => {
  const activeWorkspaceId = useActiveWorkspaceId();
  const { setActiveWorkspaceId } = useActiveWorkspaceActions();
  const isActive = activeWorkspaceId === workspaceId;
  return (
    <Flex
      align="center"
      gap="9px"
      bg={isActive ? "#F2F2F2" : "transparent"}
      px="12px"
      py="16px"
      borderRadius="4px"
      cursor={isActive ? "default" : "pointer"}
      onClick={
        !isActive
          ? () => {
              setActiveWorkspaceId(workspaceId);
            }
          : undefined
      }
    >
      <Avatar
        src={imageUrl}
        name={workspaceName}
        boxSize="36px"
        borderRadius="200px"
      />
      <Flex w="100%" align="center" justify="space-between">
        <Heading color="primary" fontSize="12px" noOfLines={1} pr="10px">
          {workspaceName}
        </Heading>
        {showSettings && (
          <Button
            maxW="115px"
            py="6px"
            h="fit-content"
            bg="white"
            color="primary"
            fontSize="12px"
            as={Link}
            to={`/user/profile/workspace?workspaceid=${workspaceId}?workspacetab=overview`}
          >
            Settings
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export const WorkSpaceMenuItems = ({ showSettings = true }) => {
  const userWorkspaces = useUserWorkspaces();
  return (
    <>
      {userWorkspaces.map(([workspaceDoc]) => {
        return (
          <WorkSpaceMenuItem
            key={workspaceDoc.uid}
            imageUrl={workspaceDoc.imageUrl}
            workspaceName={workspaceDoc.name}
            workspaceId={workspaceDoc.uid}
            showSettings={showSettings}
          />
        );
      })}
    </>
  );
};
