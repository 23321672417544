import { Box, Flex, Heading, Icon, Progress, Text } from "@chakra-ui/react";
import React from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
import OfferIcon from "../Icons/OfferIcon";

export function ResourceProgressBar(props: {
  bandWidthUsed: number;
  indicator: string;
  colorScheme: string;
}) {
  return (
    <Flex w="100%" flexDir="column" gap="8px" pb="24px">
      <Progress
        value={props.bandWidthUsed}
        colorScheme={props.colorScheme}
        size="md"
        borderRadius="full"
        w="100%"
        h="8px"
      />
      <Flex gap="4px" align="center">
        <Box boxSize="10px" borderRadius="100px" bg={props.indicator} />
        <Text fontWeight="500" color="black" opacity="0.5" fontSize="10px">
          Included
        </Text>
      </Flex>
    </Flex>
  );
}

export function ResourceUsageText({
  usageText,
  percentage,
}: {
  usageText: string;
  percentage: number;
}) {
  return (
    <Flex gap={1} pb="16px">
      <Text fontSize="12px" opacity="0.87">
        {usageText}
      </Text>
      <Text fontSize="12px" opacity="0.87">
        {`(${percentage}%)`}
      </Text>
    </Flex>
  );
}

export const ResourceCard = (props: {
  titleSection: React.ReactNode;
  resourceUsageSection: React.ReactNode;
  resourceProgressBarSection: React.ReactNode;
  resourceInfoSection: React.ReactNode;
  extraSection: React.ReactNode;
  purchaseExtraSection: React.ReactNode;
}) => {
  return (
    <Flex
      flexDir="column"
      bg="white"
      w="100%"
      borderRadius="md"
      pb="46px"
      pt="29px"
      px="24px"
    >
      <Flex flexDir="column">
        <Flex gap="4px" align="center" pb="8px">
          {props.titleSection}
        </Flex>
        {props.resourceUsageSection}
      </Flex>
      {props.resourceProgressBarSection}

      <Flex
        pb="24px"
        fontWeight="500"
        color="black"
        opacity="0.5"
        fontSize="12px"
      >
        {props.resourceInfoSection}
      </Flex>
      {props.extraSection}
      {props.purchaseExtraSection}
    </Flex>
  );
};

export const AiCreditsResourceCard = ({
  aiCreditsExtra,
  resourceUsageSection,
  resourceProgressBarSection,
  purchaseExtraSection,
}: {
  aiCreditsExtra: number;
  resourceUsageSection?: React.ReactNode;
  resourceProgressBarSection?: React.ReactNode;
  purchaseExtraSection?: React.ReactNode;
}) => {
  return (
    <Flex flexDir="column" w="330px" borderRadius="md" gap="16px">
      <Heading fontSize="24px">AI Credits</Heading>
      <ResourceCard
        titleSection={
          <Flex gap="4px" align="center" pb="8px">
            <OfferIcon fontSize="24px" />
            <Heading color="black" opacity="0.87" fontSize="13px">
              AI Credits
            </Heading>
            <IoIosInformationCircleOutline fontSize="12px" />
          </Flex>
        }
        resourceUsageSection={resourceUsageSection}
        resourceProgressBarSection={resourceProgressBarSection}
        resourceInfoSection={
          <Text fontSize="12px">
            Use AI credits to organise, manage, and analyse information to
            produce fast deliverables.
          </Text>
        }
        extraSection={
          <>
            <Flex flexDir="column" gap="16px" pb="10px">
              <Heading fontSize="13px" color="black" opacity="0.87">
                Extra AI Credits
              </Heading>
              <Heading>{aiCreditsExtra ?? 0}</Heading>
            </Flex>
            <Flex flexDir="column">
              <Text
                fontStyle="italic"
                fontWeight="400"
                fontSize="12px"
                opacity="0.50"
                color="black"
              >
                1 credit = 1000 words generated
              </Text>
              <Text
                fontStyle="italic"
                fontWeight="400"
                fontSize="12px"
                opacity="0.50"
                color="black"
              >
                1 credit = 1 image generated
              </Text>
            </Flex>
          </>
        }
        purchaseExtraSection={purchaseExtraSection}
      />
    </Flex>
  );
};

export const AiResourceCard = ({
  title,
  indicator,
  icon,
  colorScheme,
  barValue,
  barTotal,
  description,
  extraAmount,
  usageText,
  footer,
}: {
  title: string;
  indicator: string;
  icon: React.ComponentType<{ fontSize: string }>;
  colorScheme: string;
  barValue: number;
  barTotal: number;
  description: string;
  extraAmount?: number;
  usageText: string;
  footer: React.ReactNode;
}) => {
  return (
    <Flex flexDir="column" w="330px" h="440px" borderRadius="md" gap="16px">
      <Heading fontSize="24px">{title}</Heading>
      <ResourceCard
        titleSection={
          <Flex gap="4px" align="center" pb="8px">
            <Icon as={icon} fontSize="24px" />
            <Heading color="black" opacity="0.87" fontSize="13px">
              {title}
            </Heading>
            <IoIosInformationCircleOutline fontSize="12px" />
          </Flex>
        }
        resourceUsageSection={
          <ResourceUsageText
            percentage={Math.round((barValue / barTotal) * 100)}
            usageText={usageText}
          />
        }
        resourceProgressBarSection={
          <ResourceProgressBar
            bandWidthUsed={Math.round((barValue / barTotal) * 100)}
            indicator={indicator}
            colorScheme={colorScheme}
          />
        }
        resourceInfoSection={<Text>{description}</Text>}
        extraSection={
          <Flex flexDir="column" gap="16px" pb="10px">
            <Heading fontSize="13px" color="black" opacity="0.87">
              Extra {title}
            </Heading>
            <Heading>{extraAmount ?? 0}</Heading>
          </Flex>
        }
        purchaseExtraSection={
          footer
          /* <Flex pt="17px" w="100%" gap="8px">
          <Menu>
            <MenuButton
              color="black"
              border="1px solid"
              borderColor="#C2C2C2"
              as={Button}
              rightIcon={<ChevronDownIcon />}
              fontSize="12px"
            >
              {offerSizes[0].text}
            </MenuButton>
            <MenuList>
              {offerSizes.map((size) => (
                <MenuItem key={size.id}>{size.text}</MenuItem>
              ))}
            </MenuList>
          </Menu>
          <Button maxW="71px" color="white" bg="primary">
            Buy
          </Button>
        </Flex> */
        }
      />
    </Flex>
  );
};
