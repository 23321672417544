import { WorkspacePlanIds } from "./workspaceTypes";

export const COL_PROJECTS = "projects";
export const COL_ARTICLES = "articles";
export const COL_SNIPPETS = "snippets";
export const COL_CONVERSATIONS = "conversations";
export const COL_TEMPLATES = "templates";
export const COL_PROJECT_PERMISSIONS = "projectPermissions";
export const COL_RETIRED_PROJECT_PERMISSIONS = "retiredProjectPermissions";
export const COL_WORKSPACES = "workspaces";
export const COL_WORKSPACE_PERMISSIONS = "workspacePermissions";
export const COL_RETIRED_WORKSPACE_PERMISSIONS = "retiredWorkspacePermissions";
export const COL_AI_MODULES = "aiModules";
export const COL_AI_MODULE_PERMISSIONS = "aiModulePermissions";
export const COL_RETIRED_AI_MODULE_PERMISSIONS = "retiredAiModulePermissions";
export const COL_USERS = "users";

export const MONTHLY_AI_CREDITS_PER_PLAN: Record<WorkspacePlanIds, number> = {
  free: 0,
  starter: 1000,
};

export const NEW_WORKSPACE_AI_CREDITS_EXTRA = 100;
export const NEW_WORKSPACE_AI_STORAGE_TOTAL_MB = 1000;
