import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import { useAssistantContext } from "../../Context/AssistantContext";
import Assistant from "../assistant/Assistant";
import AccountMenu from "./AccountMenu";
import Header from "./Header";

export const LAYOUT_PROPS = {
  maxWidth: { base: "65vw", "2xl": "55vw" },
};

const Layout = ({
  children,
  rest,
  hideAssistant,
}: {
  children: React.ReactNode;
  rest?: React.ReactNode;
  hideAssistant?: boolean;
}): JSX.Element => {
  const { assistantIsOpen, assistantWidth } = useAssistantContext();
  const { projectId } = useParams();

  return (
    <Box pl={8} pr={4}>
      <Flex>
        <Box flex="1" py={4}>
          <Header OtherComponent={rest} />
          {children}
        </Box>
        <Box
          width={
            assistantIsOpen && !hideAssistant ? `${assistantWidth}px` : "auto"
          }
          flexShrink={0}
        >
          {!assistantIsOpen && <AccountMenu />}
          {projectId && !hideAssistant ? <Assistant /> : null}
        </Box>
      </Flex>
    </Box>
  );
};

export default Layout;
